<template>
<div v-if="reloadForm == false">
    <div v-if="item">
        <div class="grid">
            <div class="col-12">
                <div class="card">
                    <Toast/>
                    <Error :errors="errors"/>

                    <Button label="User Territory" class="p-button-rounded mb-3" />
                    <Message severity="info" :closable="false">
                        <div>Username : {{this.itemCreate.username}}</div>
                        <div>Name : {{this.itemCreate.name ? this.itemCreate.name : '-' }}</div>
                    </Message>

                    <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                        <Create :id="itemCreate.mcu_id" @submit="getDataTable()" ref="create"/>
                    </Fieldset>

                    <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit" v-if="permission.includes('read')">
                        <template #header>
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <div>
                                    <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" v-if="permission.includes('download')"/>
                                    <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" v-if="permission.includes('download')"/>
                                </div>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                                </span>
                            </div>
                        </template>
                        <Column field="number" header="No." :style="{width:'20px'}" >
                            <template #body="slotProps">
                            {{ slotProps.data.number }}
                            </template>
                        </Column>
                        <Column field="territory_code" header="Territory Code">
                            <template #body="slotProps">
                                {{slotProps.data.territory_code}}
                            </template>
                        </Column>
                        <Column field="territory_name" header="Territory Name">
                            <template #body="slotProps">
                                {{slotProps.data.territory_name}}
                            </template>
                        </Column>
                        <Column field="mcu_territory_status" header="Status">
                            <template #body="slotProps">
                                <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.mcu_territory_status == 'y'"/>
                                <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                            </template>
                        </Column>
                        <Column header="Action" headerStyle="min-width:10rem;">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)"  v-if="permission.includes('update')"/>
                            </template>
                        </Column>
                        <template #empty>
                            No data found.
                        </template>
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <template #footer>
                            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                        </template>
                    </DataTable>

                    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]" v-if="permission.includes('read')">
                        <template>
                            {{totalItemsCount}}
                        </template>
                    </Paginator>

                    <Edit @submit="getDataTable()" :item="item" ref="edit"/>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">User Not Found</h3>
    </div>
</div>
<div v-else>
    <div class="card">
        <div class="grid formgrid">
            <div class="field col-12 md:col-12">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="10rem"></Skeleton>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../../utils/helpers';
import Create from './Create.vue';
import Edit from './Edit.vue';
import Error from '../../../Error.vue';

export default {
    components: {
		'Create': Create,
		'Edit': Edit,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            reloadForm: false,

            userid: null,
            mcuid: null,

            itemCreate: {},

            // edit,delete
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    created() {
        this.userid = this.$route.params.id;
        this.getItemById(this.userid);
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/userhp-territory',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "userid" : this.userid,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/userhp-territory/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "userid" : this.userid,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'User Territory Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/userhp-territory/check',
                params: {
                    "userid": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {

                    this.$store.commit('setErrors', {});
                    this.itemCreate = res.data;

                    setTimeout(() => {
                        this.getDataTable();
                    }, 200);

                }
            }, (this))
            .catch((err) => {
                this.itemCreate = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>